export const BranchesOptions: string[] = [
    'Banken',
    'Automotive & Zulieferer',
    'Tourismus',
    'Chemie',
    'Medien',
    'Baugewerbe',
    'Finanzdienstleistung',
    'Energie',
    'Food & Beverage',
    'Ressourcen/Rohstoffe',
    'Immobilien',
    'Healthcare',
    'Hard-/Softwareentwicklung',
    'Maschinenbau',
    'Pharma & Biotechnology',
    'Logistik',
    'Andere'
];
