import { Component, Input } from '@angular/core';

export type TIconSize = 18 | 20 | 24 | 28 | 32;
export type TColor = 'Primary' | 'Secondary' | 'Info' | 'Success' | 'Warning' | 'Danger' | 'Help' | 'White' | 'Subtile' | undefined;
export type TIconType =
    | 'appendix'
    | 'arrow_down'
    | 'arrow_reference'
    | 'arrow_up'
    | 'benefits'
    | 'cat_classics'
    | 'check_mark'
    | 'chevron_down'
    | 'chevron_left'
    | 'chevron_right'
    | 'chevron_up'
    | 'clock'
    | 'company'
    | 'dashboard'
    | 'documents'
    | 'download'
    | 'edit'
    | 'employee_exit'
    | 'users'
    | 'user'
    | 'filter'
    | 'home'
    | 'import'
    | 'logout'
    | 'plus'
    | 'minus'
    | 'profile'
    | 'question_mark'
    | 'reports'
    | 'request'
    | 'search'
    | 'sparkle'
    | 'subsidies'
    | 'subsidy_child_care'
    | 'subsidy_education'
    | 'subsidy_electricity'
    | 'subsidy_fitness'
    | 'subsidy_food'
    | 'subsidy_gas'
    | 'subsidy_health'
    | 'subsidy_mobility'
    | 'subsidy_personal-development'
    | 'subsidy_public_transport'
    | 'trash'
    | 'wallet'
    | 'eye'
    | 'eye_slash'
    | 'globe'
    | 'send'
    | 'settings'
    | 'copy'
    | 'robbery';

@Component({
    selector: 'epx-svg-icon',
    template: `
        <section [style]="'transform: rotate(' + rotation + ')'" [ngClass]="getColor()" [style.width.px]="width" [style.height.px]="height">
            <svg-icon [stretch]="true" [src]="icon | epxSvgIconPath: iconSet"></svg-icon>
        </section>
    `,
    styleUrls: ['./epx-icon.component.scss']
})
export class EpxSvgIconComponent {
    @Input() icon: TIconType = 'sparkle';
    @Input() size?: number = 20;
    @Input() color?: TColor;
    @Input() iconSet?: 'ui' | 'brand' = 'ui';
    @Input() rotation = '0deg';

    get width(): number {
        return this.size || 20;
    }

    get height(): number {
        return this.size || 20;
    }

    getColor() {
        return {
            'epx-svg-icon': true,
            'epx-color-white': this.color === 'White',
            'epx-color-primary': this.color === 'Primary',
            'epx-color-secondary': this.color === 'Secondary',
            'epx-color-success': this.color === 'Success',
            'epx-color-warn': this.color === 'Warning',
            'epx-color-danger': this.color === 'Danger',
            'epx-color-info': this.color === 'Info',
            'epx-color-help': this.color === 'Help',
            'epx-color-subtile': this.color === 'Subtile'
        };
    }
}
