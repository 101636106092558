import { BaseApiModel, MoneyValueModel } from '@shared/models';

export class VoucherModel extends BaseApiModel {
    code = '';
    isUrlCode = false;
    hasOptions = false;
    option = '';
    campaign = '';
    pinCode = '';
    price: MoneyValueModel = new MoneyValueModel();
}

export class VoucherImportData {
    benefitId = '';
    benefitName = '';
    voucherCount = '';
    codesToAdd: VoucherModel[] = [];
}
