import { BenefitModel } from '@modules/benefit/models';
import { Observable, Subscription } from 'rxjs';

export class BenefitDetailViewModel {
    benefit: BenefitModel = new BenefitModel();
    protected benefitSubscription: Subscription | undefined;

    updateSubscription(benefitObserver: Observable<BenefitModel>) {
        this.benefitSubscription = benefitObserver.subscribe((benefit) => {
            this.benefit = benefit;
        });
    }

    destroy() {
        this.benefitSubscription?.unsubscribe();
    }
}
